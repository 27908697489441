import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigurationService} from "./configuration.service";
import {ApiResponse} from "../models/api-response";
import {HoroscopeList, ICreateHoroscope} from "../models/astrobase/horoscope-list";
import {Horoscope} from "../models/astrobase/horoscope";

@Injectable({
  providedIn: 'root'
})
export class HoroscopeService {

  constructor(private http: HttpClient, private configurationService: ConfigurationService) { }

  getAllHoroscope() {
    return this.http.get<ApiResponse<HoroscopeList[]>>(this.configurationService.getAllHoroscopeUrl())
      .toPromise()
      .then(res => <HoroscopeList[]>res.data)
      .then(data => { return data; });
  }

  createNewHoroscope(horoscope: ICreateHoroscope) {
    return this.http.post<ApiResponse<HoroscopeList>>(this.configurationService.createNewHoroscopeUrl(), horoscope)
      .toPromise()
      .then(res => { return res });
  }

  updateHoroscope(horoscope: ICreateHoroscope) {
    return this.http.put<ApiResponse<HoroscopeList>>(this.configurationService.updateHoroscopeUrl(horoscope.horoscopeRefNumber), horoscope)
      .toPromise()
      .then(res => { return res });
  }

  deleteHoroscope(horoscopeRefNumber: string) {
    return this.http.delete<ApiResponse<HoroscopeList>>(this.configurationService.deleteHoroscopeUrl(horoscopeRefNumber))
      .toPromise()
      .then(res => { return res });
  }

  getHoroscopeByRefNumber(horoscopeRefNumber: string) {
    return this.http.get<ApiResponse<Horoscope>>(this.configurationService.getHoroscopeByRefNumberUrl(horoscopeRefNumber))
      .toPromise()
      .then(res => <Horoscope>res.data)
      .then(data => { return data; });
  }

  transitHoroscope(horoscope: ICreateHoroscope) {
    return this.http.post<ApiResponse<Horoscope>>(this.configurationService.getTransitHoroscopeUrl(), horoscope)
      .toPromise()
      .then(res => <Horoscope>res.data)
      .then(data => { return data; });
  }

}
