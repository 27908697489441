import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {HomeComponent} from "@app/pages/home/home.component";
import {AuthGuard} from "@app/shared/helpers/auth.guard";
import {AccountService} from "@app/services/account.service";

const accountModule = () => import('@app/pages/account/account.module').then(x => x.AccountModule);
const astroBaseModule = () => import('@app/pages/astrobase/astro-base.module').then(x => x.AstroBaseModule);

const routes: Routes = [

  { path: '', component: HomeComponent, canActivate: [AuthGuard]  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]  },

  { path: 'account', loadChildren: accountModule },
  { path: 'astro-basic', loadChildren: astroBaseModule, canActivate: [AuthGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, AccountService]
})
export class AppRoutingModule { }
