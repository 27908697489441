import { Injectable } from '@angular/core';
import {Utilities} from "@app/shared/helpers";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  public baseUrl = Utilities.baseUrl();
  public loginUrl = '/login';

  constructor() { }

  public createNewHoroscopeUrl() { return this.baseUrl + '/api/horoscope/create'; }
  public updateHoroscopeUrl(horoscopeRefNumber: string) { return this.baseUrl + `/api/horoscope/${horoscopeRefNumber}`; }
  public deleteHoroscopeUrl(horoscopeRefNumber: string) { return this.baseUrl + `/api/horoscope/${horoscopeRefNumber}`; }
  public getAllHoroscopeUrl() { return this.baseUrl + '/api/horoscope/all'; }
  public getHoroscopeByRefNumberUrl(horoscopeRefNumber: string) { return this.baseUrl + `/api/Horoscope/${horoscopeRefNumber}`; }
  public getTransitHoroscopeUrl() { return this.baseUrl + '/api/horoscope/GetTransit'; }

  public getPlaceSearchTermUrl(searchTerm: string) { return this.baseUrl + `/api/Place/${searchTerm}`; }

}
