
import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {MenubarModule} from "primeng/menubar";
import {TableModule} from "primeng/table";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {SplitButtonModule} from "primeng/splitbutton";
import {MenuModule} from "primeng/menu";
import {ToastModule} from "primeng/toast";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {InputNumberModule} from "primeng/inputnumber";
import {RadioButtonModule} from "primeng/radiobutton";
import {DropdownModule} from "primeng/dropdown";
import {DialogModule} from "primeng/dialog";
import {RatingModule} from "primeng/rating";
import {FileUploadModule} from "primeng/fileupload";
import {ToolbarModule} from "primeng/toolbar";
import {InputTextareaModule} from "primeng/inputtextarea";
import {AutoCompleteModule} from "primeng/autocomplete";
import {PasswordModule} from "primeng/password";
import {CardModule} from "primeng/card";
import {CalendarModule} from "primeng/calendar";
import {BadgeModule} from 'primeng/badge';
import {ChipModule} from "primeng/chip";
import {TabViewModule} from "primeng/tabview";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {DialogService, DynamicDialogModule} from "primeng/dynamicdialog";
import {ConfirmationService, FilterService, MessageService} from "primeng/api";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {TagModule} from "primeng/tag";
import {AvatarModule} from "primeng/avatar";
import {AvatarGroupModule} from "primeng/avatargroup";
import {TreeTableModule} from "primeng/treetable";
import {SelectButtonModule} from 'primeng/selectbutton';
import {InputMaskModule} from 'primeng/inputmask';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MenubarModule, TableModule, InputTextModule, ButtonModule, RippleModule, SplitButtonModule, MenuModule,
    ToastModule, ConfirmDialogModule, InputNumberModule, RadioButtonModule, DropdownModule, DialogModule,
    RatingModule, FileUploadModule, ToolbarModule, InputTextareaModule, AutoCompleteModule, PasswordModule,
    CardModule, CalendarModule, BadgeModule, ChipModule, TabViewModule, ScrollPanelModule, DynamicDialogModule,
    OverlayPanelModule, TagModule, AvatarModule, AvatarGroupModule, TreeTableModule, SelectButtonModule,
    InputMaskModule,
  ],
  exports: [
    MenubarModule, TableModule, InputTextModule, ButtonModule, RippleModule, SplitButtonModule, MenuModule,
    ToastModule, ConfirmDialogModule, InputNumberModule, RadioButtonModule, DropdownModule, DialogModule,
    RatingModule, FileUploadModule, ToolbarModule, InputTextareaModule, AutoCompleteModule, PasswordModule,
    CardModule, CalendarModule, BadgeModule, ChipModule, TabViewModule, ScrollPanelModule, DynamicDialogModule,
    OverlayPanelModule, TagModule, AvatarModule, AvatarGroupModule, TreeTableModule, SelectButtonModule,
    InputMaskModule,
  ],
  providers: [
    ConfirmationService, MessageService, FilterService, DialogService,
  ]
})
export class CommonPrimengModule { }
