import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigurationService} from "./configuration.service";
import {ApiResponse} from "../models/api-response";
import {Place} from "../models/astrobase/place";

@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  constructor(private http: HttpClient,
              private configurationService: ConfigurationService) { }

  getPlaceSearch(searchTerm: string) {
    return this.http.get<ApiResponse<Place[]>>(this.configurationService.getPlaceSearchTermUrl(searchTerm))
      .toPromise()
      .then(res => <Place[]>res.data)
      .then(data => { return data; });
  }


}
