import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {finalize, map} from "rxjs/operators";

import { Account } from '../models/account';
import {Utilities} from "@app/shared/helpers";

// array in local storage for accounts
const accountKey = 'astro-node-account';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private accountSubject: BehaviorSubject<Account>;
  public account: Observable<Account>;
  public baseUrl = Utilities.baseUrl() + "/api/Accounts";

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.accountSubject = new BehaviorSubject<Account>(null);
    this.account = this.accountSubject.asObservable();
  }

  public get accountValue(): Account {

    if (this.accountSubject.value == null) {
      if (localStorage.getItem(accountKey) === null) {
        return null;
      }
      let account = JSON.parse(localStorage.getItem(accountKey));
      this.accountSubject.next(account);
      return account;
    }

    return this.accountSubject.value;
  }

  login(mobileNumber: string, password: string) {
    return this.http.post<any>(`${this.baseUrl}/authenticate`, { mobileNumber, password }, { withCredentials: true })
      .pipe(map(account => {
        this.accountSubject.next(account);
        localStorage.setItem(accountKey, JSON.stringify(account));
        this.startRefreshTokenTimer();
        return account;
      }));
  }

  logout() {
    this.http.post<any>(`${this.baseUrl}/revoke-token`, {}, { withCredentials: true }).subscribe();
    setTimeout(()=>{
      this.stopRefreshTokenTimer();
      this.accountSubject.next(null);
      localStorage.removeItem(accountKey);
      this.router.navigate(['/account/login']);
    },300);
  }

  refreshToken() {
    return this.http.post<any>(`${this.baseUrl}/refresh-token`, {}, { withCredentials: true })
      .pipe(map((account) => {
        this.accountSubject.next(account);
        this.startRefreshTokenTimer();
        return account;
      }));
  }

  register(account: Account) {
    return this.http.post(`${this.baseUrl}/register`, account);
  }

  verifyEmail(token: string) {
    return this.http.post(`${this.baseUrl}/verify-email`, { token });
  }

  forgotPassword(email: string) {
    return this.http.post(`${this.baseUrl}/forgot-password`, { email });
  }

  validateResetToken(token: string) {
    return this.http.post(`${this.baseUrl}/validate-reset-token`, { token });
  }

  resetPassword(token: string, password: string, confirmPassword: string) {
    return this.http.post(`${this.baseUrl}/reset-password`, { token, password, confirmPassword });
  }

  getAll() {
    return this.http.get<Account[]>(this.baseUrl);
  }

  getById(id: string) {
    return this.http.get<Account>(`${this.baseUrl}/${id}`);
  }

  create(params) {
    return this.http.post(this.baseUrl, params);
  }

  update(id, params) {
    return this.http.put(`${this.baseUrl}/${id}`, params)
      .pipe(map((account: any) => {
        // update the current account if it was updated
        if (account.id === this.accountValue.id) {
          // publish updated account to subscribers
          account = { ...this.accountValue, ...account };
          this.accountSubject.next(account);
        }
        return account;
      }));
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`)
      .pipe(finalize(() => {
        // auto logout if the logged in account was deleted
        if (id === this.accountValue.id)
          this.logout();
      }));
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

}
