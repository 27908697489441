
<div class="layout-topbar">
  <p-menubar styleClass="top-menu" [model]="items">
    <ng-template pTemplate="start">
      <div class="app-title">ASTRO NODE</div>
    </ng-template>
    <ng-template pTemplate="end">

      <!-- <div *ngIf="account">

        <div class="navbar-nav">
          <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-item nav-link">Home</a>
          <a routerLink="/astro-basic/horoscope-list" routerLinkActive="active" class="nav-item nav-link">Horoscope List</a>
          <a *ngIf="account.role === Role.Admin" routerLink="/admin" routerLinkActive="active" class="nav-item nav-link">Admin</a>
          <a (click)="logout()" class="nav-item nav-link">Logout</a>
        </div>

        <div class="lang-selection">
          <select #langSelect (change)="translateService.use(langSelect.value)">
            <option *ngFor="let lang of translateService.getLangs()" [value]="lang" [selected]="lang === translateService.currentLang">{{ lang.toUpperCase() }}</option>
          </select>
        </div>

      </div> -->

      <div class="lang-selection">
        <select #langSelect (change)="translateService.use(langSelect.value)">
          <option *ngFor="let lang of translateService.getLangs()" [value]="lang" [selected]="lang === translateService.currentLang">{{ lang.toUpperCase() }}</option>
        </select>
      </div>
      <span *ngIf="account">
        <button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-info" (click)="menu.toggle($event)"></button>
        <p-menu #menu [popup]="true" [model]="profileMenus"></p-menu>
      </span>

    </ng-template>
  </p-menubar>
</div>

<br/><br/>

<div class="app-container">

  <!-- subnav router outlet -->
  <!-- <router-outlet name="subnav"></router-outlet> -->

  <!-- global alert -->
  <app-alert></app-alert>
  <!-- global loader -->
  <app-loader></app-loader>

  <!-- main router outlet -->
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>

</div>
