import {Component, Renderer2} from '@angular/core';
import {MenuItem, PrimeIcons, PrimeNGConfig} from 'primeng/api';
import {TranslateService} from "@ngx-translate/core";
import {AccountService} from "@app/services/account.service";
import { Account, Role } from '@app/models/account';
import {LoaderService} from "@app/services/loader.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ClientApp';

  items: MenuItem[];
  profileMenus: MenuItem[];
  Role = Role;
  account: Account;

  constructor(private config: PrimeNGConfig,
              private accountService: AccountService,
              public translateService: TranslateService,
              private loaderService: LoaderService,
              private renderer: Renderer2) {

    this.accountService.account.subscribe(x => {
      this.account = x;
      this.loadMenu();
    });

    this.translateService.addLangs(['ta','en']);
    this.translateService.setDefaultLang('ta');
    this.translateService.use('ta');

    /*const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/en|ta/) ? browserLang : 'ta');*/

  }

  /* translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  } */

  ngOnInit() {

  }

  logout() {
    this.accountService.logout();
  }

  loadMenu() {

    this.items = [];
    this.profileMenus = [];

    if (this.account) {

      this.items = [
        {
          label: 'Home',
          icon: PrimeIcons.HOME,
          routerLink: ['/']
        },
        {
          label: 'Horoscope List',
          icon: PrimeIcons.LIST,
          routerLink: ['/astro-basic/horoscope-list']
        }
      ];

      this.profileMenus = [
        {
          label: 'Profile Update',
          icon: PrimeIcons.USER
        },
        {
          separator:true
        },
        {
          label:'Logout',
          icon:'pi pi-fw pi-power-off',
          command: (event) => {
            this.logout();
          }
        }
      ];

    }

  }

}
