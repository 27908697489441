import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DatePipe} from "@angular/common";

import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {OrderModule} from "ngx-order-pipe";

import { AppComponent } from './app.component';
import {AlertComponent} from "@app/shared/common-components";
import {HomeComponent} from "@app/pages/home/home.component";

import {appInitializer, AuthGuard, ErrorInterceptor, JwtInterceptor} from "@app/shared/helpers";
import {CommonPrimengModule} from "@app/common-primeng.module";

import { AppRoutingModule } from './app-routing.module';

import {AccountService} from "./services/account.service";
import {HoroscopeService} from "./services/horoscope.service";
import {PlaceService} from "./services/place.service";
import {RouterModule} from "@angular/router";
import { LoaderComponent } from './shared/common-components/loader/loader.component';
import {LoaderService} from "@app/services/loader.service";
import {LoaderInterceptor} from "@app/shared/helpers/loader.interceptor";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AlertComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonPrimengModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      defaultLanguage: 'ta'
    }),
    OrderModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    DatePipe, TranslateService, AccountService, AuthGuard, HoroscopeService, PlaceService, LoaderService,

    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
