import {Role} from "./role";

export class Account {
  id: string;
  title: string;
  fullName: string;
  mobileNumber: string;
  email: string;
  role: Role;
  jwtToken?: string;
}
