import {Injectable} from '@angular/core';

@Injectable()
export class Utilities {


  public static checkIsLocalHost(url: string, base?: string) {
    if (url) {
      const location = new URL(url, base);
      return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
    }

    return false;
  }

  public static getQueryParamsFromString(paramString: string) {
    if (!paramString) {
      return null;
    }

    const params: { [key: string]: string } = {};

    for (const param of paramString.split('&')) {
      const keyValue = Utilities.splitInTwo(param, '=');
      params[keyValue.firstPart] = keyValue.secondPart;
    }

    return params;
  }

  public static splitInTwo(text: string, separator: string): { firstPart: string, secondPart: string } {
    const separatorIndex = text.indexOf(separator);

    if (separatorIndex === -1) {
      return { firstPart: text, secondPart: null };
    }

    const part1 = text.substr(0, separatorIndex).trim();
    const part2 = text.substr(separatorIndex + 1).trim();

    return { firstPart: part1, secondPart: part2 };
  }

  public static safeStringify(object) {

    let result: string;

    try {
      result = JSON.stringify(object);
      return result;
    } catch (error) {

    }

    const simpleObject = {};

    for (const prop in object) {
      if (!object.hasOwnProperty(prop)) {
        continue;
      }
      if (typeof (object[prop]) === 'object') {
        continue;
      }
      if (typeof (object[prop]) === 'function') {
        continue;
      }
      simpleObject[prop] = object[prop];
    }

    result = '[***Sanitized Object***]: ' + JSON.stringify(simpleObject);

    return result;
  }

  public static JsonTryParse(value: string) {
    try {
      return JSON.parse(value);
    } catch (e) {
      if (value === 'undefined') {
        return void 0;
      }
      return value;
    }
  }


  public static baseUrl() {
    let base = '';

    if (window.location.origin) {
      base = window.location.origin;
    } else {
      base = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }

    return base.replace(/\/$/, '');
  }


  public static printDateOnly(date: Date) {

    date = new Date(date);

    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();
    let sup = '';
    const month = date.getMonth();
    const year = date.getFullYear();

    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
      sup = 'st';
    } else if (dayOfMonth === 2 || dayOfMonth === 22) {
      sup = 'nd';
    } else if (dayOfMonth === 3 || dayOfMonth === 23) {
      sup = 'rd';
    } else {
      sup = 'th';
    }

    const dateString = dayNames[dayOfWeek] + ', ' + dayOfMonth + sup + ' ' + monthNames[month] + ' ' + year;

    return dateString;
  }

  public static printTimeOnly(date: Date) {

    date = new Date(date);

    let period = '';
    let minute = date.getMinutes().toString();
    let hour = date.getHours();

    period = hour < 12 ? 'AM' : 'PM';

    if (hour === 0) {
      hour = 12;
    }
    if (hour > 12) {
      hour = hour - 12;
    }

    if (minute.length === 1) {
      minute = '0' + minute;
    }

    const timeString = hour + ':' + minute + ' ' + period;


    return timeString;
  }

  public static printDate(date: Date, separator = 'at') {
    return `${Utilities.printDateOnly(date)} ${separator} ${Utilities.printTimeOnly(date)}`;
  }

  public static printFriendlyDate(date: Date, separator = '-') {
    const today = new Date(); today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today); yesterday.setDate(yesterday.getDate() - 1);
    const test = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    if (test.toDateString() === today.toDateString()) {
      return `Today ${separator} ${Utilities.printTimeOnly(date)}`;
    }
    if (test.toDateString() === yesterday.toDateString()) {
      return `Yesterday ${separator} ${Utilities.printTimeOnly(date)}`;
    } else {
      return Utilities.printDate(date, separator);
    }
  }

  public static printShortDate(date: Date, separator = '/', dateTimeSeparator = '-') {

    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();

    if (day.length === 1) {
      day = '0' + day;
    }

    if (month.length === 1) {
      month = '0' + month;
    }

    return `${month}${separator}${day}${separator}${year} ${dateTimeSeparator} ${Utilities.printTimeOnly(date)}`;
  }

  public static parseDate(date) {

    if (date) {

      if (date instanceof Date) {
        return date;
      }

      if (typeof date === 'string' || date instanceof String) {
        if (date.search(/[a-su-z+]/i) === -1) {
          date = date + 'Z';
        }

        return new Date(date);
      }

      if (typeof date === 'number' || date instanceof Number) {
        return new Date(date as any);
      }
    }
  }

  public static printDuration(start: Date, end: Date) {

    start = new Date(start);
    end = new Date(end);

    // get total seconds between the times
    let delta = Math.abs(start.valueOf() - end.valueOf()) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = delta % 60;  // in theory the modulus is not required


    let printedDays = '';

    if (days) {
      printedDays = `${days} days`;
    }

    if (hours) {
      printedDays += printedDays ? `, ${hours} hours` : `${hours} hours`;
    }

    if (minutes) {
      printedDays += printedDays ? `, ${minutes} minutes` : `${minutes} minutes`;
    }

    if (seconds) {
      printedDays += printedDays ? ` and ${seconds} seconds` : `${seconds} seconds`;
    }


    if (!printedDays) {
      printedDays = '0';
    }

    return printedDays;
  }

  public static getAge(birthDate, otherDate) {
    birthDate = new Date(birthDate);
    otherDate = new Date(otherDate);

    let years = (otherDate.getFullYear() - birthDate.getFullYear());

    if (otherDate.getMonth() < birthDate.getMonth() ||
      otherDate.getMonth() === birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
      years--;
    }

    return years;
  }

  public static searchArray(searchTerm: string, caseSensitive: boolean, ...values: any[]) {
    if (!searchTerm) {
      return true;
    }

    let filter = searchTerm.trim();
    let data = values.join();

    if (!caseSensitive) {
      filter = filter.toLowerCase();
      data = data.toLowerCase();
    }

    return data.indexOf(filter) !== -1;
  }

  public static moveArrayItem(array: any[], oldIndex, newIndex) {

    if (oldIndex < 0) {
      return;
    }

    if (newIndex < 0) {
      newIndex += array.length;
    }

    if (newIndex >= array.length) {
      let k = newIndex - array.length;
      while ((k--) + 1) {
        array.push(undefined);
      }
    }

    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  }

  public static convertDMSToDEG(dms) {
    var dms_Array = dms.split(/[^\d\w\.]+/);
    var degrees = dms_Array[0];
    var minutes = dms_Array[1];
    var seconds = dms_Array[2];
    var direction = dms_Array[3];

    var deg = (Number(degrees) + Number(minutes)/60 + Number(seconds)/3600).toFixed(6);

    // if (direction == "S" || direction == "W") {
    //  deg = deg * -1;
    // } // Don't do anything for N or E
    return deg;
  }

  public static convertDEGToDMS(deg, lat) {
    var absolute = Math.abs(deg);

    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

    if (lat) {
      var direction = deg >= 0 ? "N" : "S";
    } else {
      var direction = deg >= 0 ? "E" : "W";
    }

    return degrees + "°" + minutes + "'" + seconds + "\"" + direction;
  }

  public static toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return degrees + " " + minutes + " " + seconds;
  }

  public static convertDMS(lat, lng) {
    var latitude = Utilities.toDegreesMinutesAndSeconds(lat);
    var latitudeCardinal = lat >= 0 ? "N" : "S";

    var longitude = Utilities.toDegreesMinutesAndSeconds(lng);
    var longitudeCardinal = lng >= 0 ? "E" : "W";

    return latitude + " " + latitudeCardinal + "\n" + longitude + " " + longitudeCardinal;
  }

  /**
   * Converts decimal degrees to degrees minutes seconds.
   *
   * @param dd the decimal degrees value.
   * @return degrees minutes seconds string in the format 49°15'51.35"N
   */
  public static convertToDms(dd) {
    var absDd = Math.abs(dd);
    var deg = absDd | 0;
    var frac = absDd - deg;
    var min = (frac * 60) | 0;
    var sec = frac * 3600 - min * 60;
    // Round it to 2 decimal points.
    sec = Math.round(sec * 100) / 100;
    var sec2 = sec | 0;

    return (Utilities.padLeadingZeros(deg, 2) + ":" + Utilities.padLeadingZeros(min, 2) + ":" + Utilities.padLeadingZeros(sec2, 2));
  }

  public static padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  public static convertToDms2Digit(dd) {
    var absDd = Math.abs(dd);
    var deg = absDd | 0;
    var frac = absDd - deg;
    var min = (frac * 60) | 0;
    // var sec = frac * 3600 - min * 60;
    // Round it to 2 decimal points.
    // sec = Math.round(sec * 100) / 100;
    // var sec2 = sec | 0;

    return (Utilities.padLeadingZeros(deg, 2) + ":" + Utilities.padLeadingZeros(min, 2));
  }

  public static romanize (num) {
    if (isNaN(num))
      return NaN;
    var digits = String(+num).split(""),
      key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
        "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
        "","I","II","III","IV","V","VI","VII","VIII","IX"],
      roman = "",
      i = 3;
    while (i--)
      roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  }

}
